import Home from './panels/Home';
import "./assets/css/main.css";
import Webpage from "./panels/Webpage";
import React, { useState,useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import { View, AdaptivityProvider, AppRoot } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import queryString from 'query-string';
import ApiService from './services/api';
import $ from "jquery";

const parsed = queryString.parse(window.location.search);
const vk_group_id = parsed.vk_group_id;
const App = () => {
	const [activePanel, setActivePanel] = useState('home');
	const [popout] = useState(null);
	const [Api] = useState(ApiService({}))
	const go = e => {
		setActivePanel(e);
	};
  useEffect(()=>{
    bridge.send("VKWebAppSetViewSettings",
      {"status_bar_style": "light", "action_bar_color": "#3a3b5e","navigation_bar_color":"#3a3b5e"});
    $('body').css('overflow', 'auto');
  },[])
	return (
		<AdaptivityProvider>
			<AppRoot>
				<View activePanel={activePanel} popout={popout}>
					<Home vk_group_id={vk_group_id} id='home' bridge={bridge} go={go} api={Api} />
					<Webpage id={"webpage"}/>
				</View>
			</AppRoot>
		</AdaptivityProvider>
	);
}

export default App;
