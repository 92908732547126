
import React from "react"
import { Panel } from "@vkontakte/vkui";
import img1 from "../assets/img/logo_a.gif";
import env from "react-dotenv";

const Webpage = ({ id }) => {
  return (
    <Panel id={id}>

      <div style={{ height: "100vh" }} className="back_2 back_web_page">
        <div className="content">
          <img className="center_img img1" src={img1} alt="" />
          <div className="texts">
            <h2 style={{ color: "#ffc25e" }}>
              «КОВАЛЬСКИЙ ВИДЖЕТ»
            </h2>

            <h3 style={{ color: "white" }}>
              Спасибо за установку
            </h3>
            <p>
              Мы уже отправили вам подробную инструкцию в личные сообщения
            </p>
          </div>

          <a target="_blank" href={`https://m.vk.com/im?sel=-${env.VK_GROUP_ID}`} className="connect_but">
            <span>Перейти к инструкции</span>
          </a>
        </div>
      </div>


    </Panel>
  )
}

export default Webpage;
