import React, { useEffect, useState } from 'react';
import env from "react-dotenv";
import PropTypes from 'prop-types';
import { Panel } from '@vkontakte/vkui';
import img1 from "../assets/img/logo_a.gif";

import sky from "../assets/img/sk.png";

import { ReactComponent as Student } from "../assets/img/student.svg";
import { ReactComponent as Editor } from "../assets/img/editor.svg";
import { ReactComponent as Ready } from "../assets/img/042-display.svg";
import { ReactComponent as Statics } from "../assets/img/browser.svg";
import { ReactComponent as Support } from "../assets/img/039-wireframe.svg";
import { ReactComponent as Idea } from "../assets/img/002-idea.svg";
import { ReactComponent as CloseModal } from "../assets/img/close.svg";
import { ReactComponent as Sova } from "../assets/img/pit.svg";

import key from "../assets/img/key.png";
import close from "../assets/img/close.png";
import pit from "../assets/img/PIT.png";
import $ from "jquery"
const Home = ({ id, go, bridge, vk_group_id, api }) => {
  const sendMessage = async () => {
    let { result } = await bridge.send("VKWebAppAllowMessagesFromGroup", { "group_id": parseInt(env.VK_GROUP_ID), "key": Date.now() });
    if (result) {
      let userData = await bridge.send("VKWebAppGetUserInfo");
      if (userData && userData.id) {
        let buff = new Buffer(JSON.stringify({
          user_id: userData.id,
          first_name: userData.first_name
        }));
        let base64data = buff.toString('base64');

        await api.$repo.messages.send({ sign: base64data });
      }
    }
    go("webpage");
  };
  const connectApp = async () => {
    if (vk_group_id === undefined) {
      await bridge.send("VKWebAppAddToCommunity");
    } else {
      let url = `https://vk.com/app${env.VK_APP_ID}_-${vk_group_id}`;
      window.open(
        url,
        '_blank'
      );
    }
  }
  const [modal, setModal] = useState(null);

  useEffect(() => {
    bridge.subscribe(({ detail: { type, data } }) => {

      if (type === "VKWebAppAddToCommunityResult") {
        let grp_id = data.group_id;
        bridge.send("VKWebAppGetGroupInfo", { "group_id": grp_id });

      }
      if (type === "VKWebAppGetGroupInfoResult") {
        setModal(data);
      }
    });

  }, [])
  useEffect(()=>{
    if(modal){
      $('body').css('overflow', 'hidden');
    }else{
      $('body').css('overflow', 'auto');
    }
  },[modal])
  var decoder = new TextDecoder('utf-8');
  return (
    <Panel id={id}>

      <div className="back_2">
        <div className="content">
          <img alt="" className="center_img img1" src={img1} />
          <div className="texts">
            <h2 style={{ color: "#ffc25e" }}>
              «КОВАЛЬСКИЙ ВИДЖЕТ»
            </h2>

            <h3 style={{ color: "white" }}>
              {vk_group_id !== undefined ? "Спасибо за установку" : "Подключите умные виджеты"}

            </h3>
            <p>
              Таргетируйте умные виджеты под вашу аудиторию
              и превращайте 50% своих посетителей в заявки
            </p>
          </div>
          {vk_group_id !== undefined ?
            <button data-to={"webpage"} onClick={sendMessage} className="connect_but">
              Открыть веб-версию VK
            </button>
            :
            <button onClick={connectApp} className="connect_but">
              Подключить приложение
            </button>
          }


          <div style={{ marginTop: 20 }} className="texts">
            <h3 style={{ color: "white" }}>
              ВОЗМОЖНОСТИ СЕРВИСА
            </h3>
            <p>
              Простой и мощный редактор виджетов ВКонтакте,&nbsp;
              <span style={{ color: "#ffc25e" }}>
                настройка приложения доступна только
                в полной версии сайта вконтакте.
              </span>
            </p>
          </div>
          <div className="info_block">
            <div className="imgs">
              <Editor className="rel_img" fill={"#ffc25e"}/>
              <img alt="" className="ab_img" src={sky} />
            </div>
            <div style={{ marginTop: 0, zIndex: 2 }} className="texts">
              <h3 style={{ color: "white", fontSize: "3.5vw", marginTop: 0 }}>
                Визуальный редактор
              </h3>
              <p>
                Легко и быстро редактируйте
                текст, картинки, ссылки и другие
                элементы виджета.
              </p>
            </div>
          </div>
          <div className="info_block">
            <div className="imgs">
              <Ready className="rel_img" fill={"#ffc25e"}/>
              <img alt="" className="ab_img" src={sky} />
            </div>
            <div style={{ marginTop: 0, zIndex: 2 }} className="texts">
              <h3 style={{ color: "white", fontSize: "3.5vw", marginTop: 0 }}>
                Готовые шаблоны
              </h3>
              <p>
                Экономьте время на обдумывание
                идеи виджета и настройте 1 из 14
                готовых шаблонов виджетов.
              </p>
            </div>
          </div>
          <div className="info_block">
            <div className="imgs">
              <Student className="rel_img" fill={"#ffc25e"}/>
              <img alt="" className="ab_img" src={sky} />
            </div>
            <div style={{ marginTop: 0, zIndex: 2 }} className="texts">
              <h3 style={{ color: "white", fontSize: "3.5vw", marginTop: 0 }}>
                Выбор аудитории
              </h3>
              <p>
                Делайте разные предложения для
                разных групп аудиторий по полу,
                возрасту, городу, дню рождения,
                интересам, VK ID и другим
                параметрам.
              </p>
            </div>
          </div>
          <div className="info_block">
            <div className="imgs">
              <Statics className="rel_img" fill={"#ffc25e"}/>

              <img alt="" className="ab_img" src={sky} />
            </div>
            <div style={{ marginTop: 0, zIndex: 2 }} className="texts">
              <h3 style={{ color: "white", fontSize: "3.5vw", marginTop: 0 }}>
                Статистика
              </h3>
              <p>
                Собирайте статистику по кликам
                в vk.cc и найдите лучший вариант
                виджета, который приносит
                больше результата.
              </p>
            </div>
          </div>
          <div className="info_block">
            <div className="imgs">
              <Support className="rel_img" fill={"#ffc25e"}/>
              <img alt="" className="ab_img" src={sky} />
            </div>
            <div style={{ marginTop: 0, zIndex: 2 }} className="texts">
              <h3 style={{ color: "white", fontSize: "3.5vw", marginTop: 0 }}>
                Тех поддержка
              </h3>
              <p>
                Если у вас появятся вопросы, то
                мы быстро поможем решить их и
                подскажем, как действовать
                дальше.
              </p>
            </div>
          </div>
          <div className="info_block">
            <div className="imgs">
              <Idea className="rel_img" fill={"#ffc25e"}/>
              <img alt="" className="ab_img" src={sky} />
            </div>
            <div style={{ marginTop: 0, zIndex: 2 }} className="texts">
              <h3 style={{ color: "white", fontSize: "3.5vw", marginTop: 0 }}>
                Виджет «под ключ»
              </h3>
              <p>
                Придумаем эффективный виджет
                под ваши аудитории и мы
                настроим ковальский виджет
                с гарантией результата.
              </p>
            </div>
          </div>
          {vk_group_id !== undefined ?
            <button data-to={"webpage"} onClick={sendMessage} className="connect_but">
              Открыть веб версию VK
            </button>
            :
            <button onClick={connectApp} className="connect_but">
              Подключить приложение
            </button>
          }
          <div style={{ marginTop: 20 }} className="texts">
            <p>
              Простой и мощный редактор виджетов ВКонтакте, &nbsp;
              <span style={{ color: "#ffc25e" }}>
                настройка приложения доступна только
                в полной версии сайта вконтакте.
              </span>
            </p>
          </div>
        </div>
      </div>
      {modal !== null &&
        <div className="modal">
          <div className="back" />
          <div className="modal_content">
            <CloseModal fill={'white'} alt="" className="close_modal" src={close} onClick={() => setModal(null)} />
            <div className="main_content">
              <Sova  />
              <div style={{ marginTop: 25, zIndex: 2, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }} className="texts">
                <h3 style={{ color: "white", fontSize: "3.5vw", marginTop: 0 }}>
                  Продолжить работу?
                </h3>
                <p style={{ width: "50%", alignSelf: "center" }}>
                  Вы хотите перейти в «{unescape(modal.name.split("&#33;").join("!"))}»
                  для продолжения работы с приложением?
                </p>
              </div>
              <div className="buttons">
                <button  id={"no_modal"} onClick={() => setModal(null)} className="connect_but no">
                  Нет
                </button>
                <a target="_blank" href={`https://vk.com/${modal.screen_name}`} onClick={() => {
                }} className="connect_but">
                  <span>Да</span>
                </a>
              </div>

            </div>

          </div>
        </div>
      }

    </Panel>
  )
};

Home.propTypes = {
  id: PropTypes.string.isRequired,
  go: PropTypes.func.isRequired,
};

export default Home;
