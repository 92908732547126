import axios from 'axios';
import apiMessages from './repositories/messages';
import env from "react-dotenv";

export default (app) => {
  let client = null;
  if (env.API_URL) {
    client = axios.create({
      baseURL: env.API_URL
    });

    const repositories = {
      messages: apiMessages(client),
    };

    app.$repo = repositories;
  }

  app.$client = client;

  return app;
};
